/*
 * Author: dizhong zhu
 * Date: 10/10/2024
 */

import http from './http'
import { QueryParamsT, GarmentTaggingT, GarmentTaggingRequestT, GarmentTaggingDownloadRequestT } from 'types/garment-tagging'

export async function listGarmentTaggingRequest(domain: string, params: QueryParamsT) {
    return (
        await http.get(`/dashboard/garment-tagging/list`, {
            params: {
                domain: domain,
                page: params.page,
                page_size: params.page_size,
                sort_by: params.sort_by,
                sort_desc: params.sort_desc,
                search: params.search,
                start_date: params.start_date,
                end_date: params.end_date,
            },
        })
    ).data
}

export async function delGarmentTaggingRequest(data: GarmentTaggingRequestT) {
    return (
        await http.delete(`/dashboard/garment-tagging?domain=${data.domain}`, {
            data: { uuids: data.uuids, mode: data.mode },
        })
    ).data
}

export async function downloadGarmentTaggingRequest(data: GarmentTaggingDownloadRequestT) {
    return (await http.post(`/dashboard/garment-tagging/download`, data)).data
}
