/*
 * Author: dizhong zhu
 * Date: 10/02/2025
 */

import React, { useState } from 'react'
import { X } from 'lucide-react'
import { GarmentTaggingT } from 'types/garment-tagging'
import { QueueStatusBanner } from './queueStatus'

interface GarmentDetailModalProps {
    isOpen: boolean
    onClose: () => void
    garment: GarmentTaggingT
}

const GarmentDetailModal = ({ isOpen, onClose, garment }: GarmentDetailModalProps) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)

    if (!isOpen) return null

    // Helper function to format JSON data for display
    const formatJsonDisplay = (data: any): React.ReactNode => {
        if (!data) return 'No data available'

        // If data is a string, try to parse it as JSON
        let parsedData = data
        if (typeof data === 'string') {
            try {
                parsedData = JSON.parse(data)
            } catch (e) {
                return data // If parsing fails, return the original string
            }
        }

        const formatValue = (value: any): string => {
            if (value === null) return 'null'
            if (value === undefined) return 'undefined'
            if (typeof value === 'object') return JSON.stringify(value)
            return String(value)
        }

        if (typeof parsedData !== 'object' || parsedData === null) {
            return String(parsedData)
        }

        return Object.entries(parsedData).map(([key, value]) => (
            <div key={key} className="mb-2">
                <span className="font-medium">{key.replace(/_/g, ' ')}: </span>
                <span>{formatValue(value)}</span>
            </div>
        ))
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-hidden flex flex-col">
                {/* Header */}
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-xl font-semibold">Garment Details</h2>
                    <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded-full">
                        <X className="h-6 w-6" />
                    </button>
                </div>

                {/* Content */}
                <div className="flex-1 overflow-y-auto p-6">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        {/* Left Column - Images */}
                        <div className="space-y-6">
                            <div className="space-y-4">
                                <h3 className="text-lg font-semibold">Input Images</h3>
                                {/* Main Image Display */}
                                <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
                                    <img src={garment.input_imgs[selectedImageIndex]} alt={`Input image ${selectedImageIndex + 1}`} className="w-full h-full object-contain" />
                                </div>
                                {/* Thumbnail Navigation */}
                                {garment.input_imgs.length > 1 && (
                                    <div className="flex gap-2 overflow-x-auto pb-2">
                                        {garment.input_imgs.map((img, index) => (
                                            <button
                                                key={index}
                                                onClick={() => setSelectedImageIndex(index)}
                                                className={`flex-none w-20 h-20 rounded-lg overflow-hidden border-2 
                                                    ${selectedImageIndex === index ? 'border-blue-500' : 'border-transparent'}`}
                                            >
                                                <img src={img} alt={`Thumbnail ${index + 1}`} className="w-full h-full object-cover" />
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {/* Annotated Image */}
                            {garment.output_measure_img && (
                                <div className="space-y-4">
                                    <h3 className="text-lg font-semibold">Annotated Image</h3>
                                    <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
                                        <img src={garment.output_measure_img} alt="Annotated image" className="w-full h-full object-contain" />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Right Column - Details */}
                        <div className="space-y-6">
                            {/* Basic Info */}
                            <div className="space-y-4">
                                <h3 className="text-lg font-semibold">Basic Information</h3>
                                <div className="bg-gray-50 rounded-lg p-4 space-y-2">
                                    <div>
                                        <span className="font-medium">UUID: </span>
                                        {garment.uuid}
                                    </div>
                                    <div>
                                        <span className="font-medium">
                                            Status: <QueueStatusBanner status={garment.status} />{' '}
                                        </span>
                                        {garment.queue_position && <span className="ml-2 text-sm text-gray-600">(Queue Position: {garment.queue_position})</span>}
                                    </div>
                                    <div>
                                        <span className="font-medium">Created At: </span>
                                        {new Date(garment.created_at).toLocaleString()}
                                    </div>
                                </div>
                            </div>

                            {/* Input Attributes */}
                            <div className="space-y-4">
                                <h3 className="text-lg font-semibold">Input Attributes</h3>
                                <div className="bg-gray-50 rounded-lg p-4">{formatJsonDisplay(garment.input_attribute)}</div>
                            </div>

                            {/* Measurements */}
                            <div className="space-y-4">
                                <h3 className="text-lg font-semibold">Measurements</h3>
                                <div className="bg-gray-50 rounded-lg p-4">{formatJsonDisplay(garment.output_measure)}</div>
                            </div>

                            {/* Tagging */}
                            <div className="space-y-4">
                                <h3 className="text-lg font-semibold">Tagging</h3>
                                <div className="bg-gray-50 rounded-lg p-4">{formatJsonDisplay(garment.output_tagging)}</div>
                            </div>

                            {/* Description */}
                            {garment.output_description && (
                                <div className="space-y-4">
                                    <h3 className="text-lg font-semibold">Description</h3>
                                    <div className="bg-gray-50 rounded-lg p-4">{formatJsonDisplay(garment.output_description)}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GarmentDetailModal
