/*
 * Author: dizhong zhu
 * Date: 29/01/2025
 */

import React, { useEffect, useState } from 'react'
import { useReactTable, getCoreRowModel, flexRender, createColumnHelper, PaginationState, SortingState, OnChangeFn } from '@tanstack/react-table'
import { GarmentTaggingT, GTQueueStatusT } from 'types/garment-tagging'
import { IndeterminateCheckbox } from 'components/IndeterminateCheckbox'
import { ChevronDown, ChevronUp, ChevronsUpDown, Download } from 'lucide-react'
import { ImageWithPreview, JsonWithTooltip, TextWithTooltip } from 'widgets/TableCellTooltip'
import { DebouncedInput } from 'components/DebouncedInput'
import EmailModal from '../../components/EmailModal'
import { QueueStatusBanner } from './queueStatus'

const columnHelper = createColumnHelper<GarmentTaggingT>()

interface Props {
    data: GarmentTaggingT[]
    totalItems: number
    totalCompletedItems: number
    pageCount: number
    onPaginationChange: OnChangeFn<PaginationState>
    onSortingChange: OnChangeFn<SortingState>
    onDeleteSelected: (items: string[]) => void
    onDownloadSelected: (email: string) => void
    onExportToExcel: () => void
    onSearchChange: (value: string) => void
    pagination: PaginationState
    sorting: SortingState
    searchTerm?: string
    selectedIds: Set<string>
    onSelectRow: (uuid: string, checked: boolean) => void
    onSelectPage: (checked: boolean) => void
    isSelectAllMode: boolean
    onSelectAll: (checked: boolean) => void
    isItemSelected: (uuid: string) => boolean
    getSelectedCount: () => Promise<number>
    onRowClick: (garment: GarmentTaggingT) => void
}

export const GarmentTable = ({
    data,
    totalItems,
    totalCompletedItems,
    pageCount,
    onPaginationChange,
    onSortingChange,
    onDeleteSelected,
    onDownloadSelected,
    onSearchChange,
    pagination,
    sorting,
    searchTerm,
    onSelectRow,
    isSelectAllMode,
    onSelectAll,
    isItemSelected,
    getSelectedCount,
    onRowClick,
}: Props) => {
    const [rowSelection, setRowSelection] = useState({})
    const [pageNumber, setPageNumber] = useState(pagination.pageIndex + 1)
    const [searchValue, setSearchValue] = useState<string>(searchTerm || '')
    const [selectedCount, setSelectedCount] = useState<number>(0)
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)

    useEffect(() => {
        const updateCount = async () => {
            const count = await getSelectedCount()
            setSelectedCount(count)
        }
        updateCount()
    }, [isSelectAllMode, data, getSelectedCount])

    const columns = [
        columnHelper.display({
            id: 'select',
            header: ({ table }) => (
                <div className="flex items-center">
                    <IndeterminateCheckbox
                        checked={isSelectAllMode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onSelectAll(e.target.checked)
                        }}
                    />
                    {/*{isSelectAllMode && <span className="ml-2 text-xs text-gray-500">All items selected</span>}*/}
                </div>
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox checked={isItemSelected(row.original.uuid)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelectRow(row.original.uuid, e.target.checked)} />
            ),
        }),
        columnHelper.accessor('uuid', {
            header: 'UUID',
            cell: (info) => (
                <button
                    onClick={(e) => {
                        e.stopPropagation()
                        onRowClick(info.row.original)
                    }}
                    className="text-blue-600 hover:text-blue-800 hover:underline text-left"
                >
                    <TextWithTooltip content={info.getValue()} />
                </button>
            ),
            enableSorting: false,
            size: 150,
        }),
        columnHelper.accessor('input_imgs', {
            header: 'Input Image',
            cell: (info) => <ImageWithPreview src={info.getValue()[0]} alt="Input" />,
            enableSorting: false,
            size: 100,
        }),
        columnHelper.accessor('input_attribute', {
            header: 'Input Attributes',
            cell: (info) => <JsonWithTooltip data={info.getValue()} />,
            enableSorting: false,
            size: 150,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: (info) => <QueueStatusBanner status={info.getValue()} />,
            enableSorting: false,
            size: 80,
        }),
        columnHelper.accessor('output_measure_img', {
            header: 'Annotated Image',
            cell: (info) => <ImageWithPreview src={info.getValue()} alt="Annotated" />,
            enableSorting: false,
            size: 100,
        }),
        columnHelper.accessor('output_tagging', {
            header: 'Tagging',
            cell: (info) => <JsonWithTooltip data={info.getValue()} />,
            enableSorting: false,
            size: 150,
        }),
        columnHelper.accessor('output_measure', {
            header: 'Measurements',
            cell: (info) => <JsonWithTooltip data={info.getValue()} />,
            enableSorting: false,
            size: 150,
        }),
        columnHelper.accessor('output_description', {
            header: 'Description',
            cell: (info) => <TextWithTooltip content={info.getValue() || ''} />,
            enableSorting: false,
            size: 150,
        }),
        columnHelper.accessor('created_at', {
            header: 'Time Stamp',
            cell: (info) => new Date(info.getValue()).toLocaleString(),
            size: 150,
        }),
    ]

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            pagination,
            sorting,
        },
        pageCount,
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onPaginationChange,
        onSortingChange,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        manualSorting: true,
    })

    const selectedRows = Object.keys(rowSelection).length

    const handleSearchChange = (value: string | number) => {
        const searchStr = value.toString()
        setSearchValue(searchStr)
        if (searchStr !== searchValue) {
            onSearchChange(searchStr)
        }
    }

    const handleDownloadClick = () => {
        if (selectedCount > 0) {
            setIsEmailModalOpen(true)
        }
    }

    return (
        <div className="flex flex-col h-full">
            {/* Action Buttons Section */}
            <div className="flex-none pb-4">
                <div className="flex justify-between items-center">
                    <div>
                        <DebouncedInput value={searchValue} onChange={handleSearchChange} placeholder="Press Enter to search..." debounce={0} />
                    </div>
                    <div className="flex items-center gap-4">
                        {isSelectAllMode && <span className="text-sm text-gray-600">{selectedCount} items selected across all pages</span>}
                        <button
                            onClick={handleDownloadClick}
                            disabled={selectedCount === 0}
                            className={`flex items-center gap-2 px-4 py-2 rounded transition-colors
                                ${selectedCount === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'}`}
                        >
                            <Download className="h-4 w-4" />
                            Download ({selectedCount})
                        </button>
                        <button
                            onClick={() => onDeleteSelected([])} // Empty array as actual items are handled in parent
                            disabled={selectedCount === 0}
                            className={`px-4 py-2 rounded transition-colors ${selectedCount === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-600 text-white hover:bg-red-700'}`}
                        >
                            Delete Selected ({selectedCount})
                        </button>
                    </div>

                    <EmailModal
                        isOpen={isEmailModalOpen}
                        onClose={() => setIsEmailModalOpen(false)}
                        onSubmit={onDownloadSelected}
                        title="Download Selected Items"
                        description="Enter your email address to receive the download link for selected items."
                    />
                </div>
            </div>

            {/* Table Section */}
            <div className="flex-1 min-h-0 border border-gray-200 rounded-lg overflow-hidden">
                <div className="h-full overflow-auto">
                    <table className="w-full">
                        <thead className="sticky top-0 z-10 bg-gray-50">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className="px-4 py-3 text-left text-sm text-gray-900 font-semibold border-b border-gray-200"
                                            style={{ width: header.column.columnDef.size }}
                                        >
                                            <div
                                                className={`flex items-center gap-2 ${header.column.getCanSort() ? 'cursor-pointer' : ''}`}
                                                onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {header.column.getCanSort() && (
                                                    <span>
                                                        {header.column.getIsSorted() === 'asc' ? (
                                                            <ChevronUp className="h-4 w-4" />
                                                        ) : header.column.getIsSorted() === 'desc' ? (
                                                            <ChevronDown className="h-4 w-4" />
                                                        ) : (
                                                            <ChevronsUpDown className="h-4 w-4" />
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {table.getRowModel().rows.map((row) => (
                                <tr key={row.id} className="bg-white hover:bg-gray-50 transition-colors">
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} className="px-4 py-2.5 text-sm text-gray-900" style={{ width: cell.column.columnDef.size }}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination Section */}
            <div className="flex-none pt-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 text-sm text-gray-700">
                        <span>
                            Page {pagination.pageIndex + 1} of {pageCount}
                        </span>
                        <span className="text-gray-400">|</span>
                        <span>Total items: {totalItems}</span>
                        <span>Total complete: {totalCompletedItems}</span>
                    </div>

                    <div className="flex items-center gap-2">
                        <button
                            onClick={() =>
                                onPaginationChange({
                                    pageIndex: 0,
                                    pageSize: pagination.pageSize,
                                })
                            }
                            disabled={!table.getCanPreviousPage()}
                            className="px-3 py-1 rounded border border-gray-300 hover:bg-gray-50
                                     disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {'<<'}
                        </button>
                        <button
                            onClick={() =>
                                onPaginationChange({
                                    pageIndex: pagination.pageIndex - 1,
                                    pageSize: pagination.pageSize,
                                })
                            }
                            disabled={!table.getCanPreviousPage()}
                            className="px-3 py-1 rounded border border-gray-300 hover:bg-gray-50
                                     disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {'<'}
                        </button>

                        <div className="flex items-center gap-1">
                            <span>Page</span>
                            <input
                                type="number"
                                min={1}
                                max={pageCount}
                                value={pageNumber}
                                onChange={(e) => {
                                    const value = e.target.value
                                    setPageNumber(Math.max(1, Math.min(pageCount, parseInt(value) || 1)))
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        const validPage = Math.max(1, Math.min(pageNumber, pageCount))
                                        onPaginationChange({
                                            pageIndex: validPage - 1,
                                            pageSize: pagination.pageSize,
                                        })
                                    }
                                }}
                                className="w-16 px-2 py-1 rounded border border-gray-300"
                            />
                            <span>of {pageCount}</span>
                        </div>

                        <button
                            onClick={() =>
                                onPaginationChange({
                                    pageIndex: pagination.pageIndex + 1,
                                    pageSize: pagination.pageSize,
                                })
                            }
                            disabled={!table.getCanNextPage()}
                            className="px-3 py-1 rounded border border-gray-300 hover:bg-gray-50
                                     disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {'>'}
                        </button>
                        <button
                            onClick={() =>
                                onPaginationChange({
                                    pageIndex: pageCount - 1,
                                    pageSize: pagination.pageSize,
                                })
                            }
                            disabled={!table.getCanNextPage()}
                            className="px-3 py-1 rounded border border-gray-300 hover:bg-gray-50
                                     disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {'>>'}
                        </button>

                        <select
                            value={pagination.pageSize}
                            onChange={(e) => {
                                const newPageSize = Number(e.target.value)
                                localStorage.setItem('garmentTablePageSize', newPageSize.toString())
                                onPaginationChange({
                                    pageIndex: 0,
                                    pageSize: newPageSize,
                                })
                            }}
                            className="px-3 py-1 rounded border border-gray-300 hover:bg-gray-50"
                        >
                            {[10, 20, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}
