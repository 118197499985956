/*
 * Author: dizhong zhu
 * Date: 30/01/2025
 */

import React, { useCallback, useEffect, useState } from 'react'
import { TimeFilters } from 'widgets/TimeFilter'
import { GarmentTaggingT, QueryParamsT } from 'types/garment-tagging'
import { GarmentTable } from './garmentTable'
import { OnChangeFn, PaginationState, SortingState } from '@tanstack/react-table'
import { useAppDispatch, useAppSelector } from 'store'
import { delGarmentTagging, downloadGarmentTagging, listGarmentTagging } from 'store/garment-tagging'
import { listGarmentTaggingRequest } from 'services/garment-tagging'
import PageLoader from 'components/PageLoader'
import toast from 'react-hot-toast'
import { showConfirmDialog } from '../../components/ConfirmModal'
import GarmentDetailModal from './garmentDetail'

interface Props {
    domain: string
}

export const GarmentTaggingContent = ({ domain }: Props) => {
    const defaultPageSize = 10

    const dispatch = useAppDispatch()

    const [startDate, setStartDate] = useState<Date | undefined>()
    const [endDate, setEndDate] = useState<Date | undefined>()
    const [searchTerm, setSearchTerm] = useState<string>('')

    const [selectedGarment, setSelectedGarment] = useState<GarmentTaggingT | null>(null)
    const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set())
    const [isSelectAllMode, setIsSelectAllMode] = useState(false)
    const [excludedIds, setExcludedIds] = useState<Set<string>>(new Set())

    // State for table controls
    const [pagination, setPagination] = useState<PaginationState>(() => {
        const storedPageSize = localStorage.getItem('garmentTablePageSize')
        return {
            pageIndex: 0,
            pageSize: storedPageSize ? parseInt(storedPageSize) : defaultPageSize,
        }
    })

    const [sorting, setSorting] = useState<SortingState>([{ id: 'created_at', desc: true }])
    const { garmentTaggingItems, totalItems, totalCompletedItems, totalPages, currentPage, isGarmentTaggingLoading } = useAppSelector((state) => state.garmentTagging)

    // Load data when domain changes
    useEffect(() => {
        if (domain) {
            loadData({
                pagination,
                sorting,
                startDate,
                endDate,
            })
        }
    }, [domain])

    const loadData = async ({ pagination, sorting, startDate, endDate, search }: { pagination: PaginationState; sorting: SortingState; startDate?: Date; endDate?: Date; search?: string }) => {
        if (!domain) return

        const queryParams: QueryParamsT = {
            page: pagination.pageIndex + 1,
            page_size: pagination.pageSize,
            sort_by: sorting[0]?.id || 'created_at',
            sort_desc: sorting[0]?.desc ?? true,
            search: search,
            start_date: startDate?.toISOString(),
            end_date: endDate?.toISOString(),
        }

        await dispatch(listGarmentTagging({ domain: domain, queryParams }))
    }

    // Selection handlers
    const handleSelect = (uuid: string, checked: boolean) => {
        if (isSelectAllMode) {
            setExcludedIds((prev) => {
                const next = new Set(prev)
                if (!checked) {
                    next.add(uuid)
                } else {
                    next.delete(uuid)
                }
                return next
            })
        } else {
            setSelectedIds((prev) => {
                const next = new Set(prev)
                if (checked) {
                    next.add(uuid)
                } else {
                    next.delete(uuid)
                }
                return next
            })
        }
    }

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            setIsSelectAllMode(true)
            setExcludedIds(new Set())
            setSelectedIds(new Set())
        } else {
            setIsSelectAllMode(false)
            setExcludedIds(new Set())
            setSelectedIds(new Set())
        }
    }

    const handleSelectPage = (checked: boolean) => {
        setSelectedIds((prev) => {
            const next = new Set(prev)
            garmentTaggingItems.forEach((item) => {
                if (checked) {
                    next.add(item.uuid)
                } else {
                    next.delete(item.uuid)
                }
            })
            return next
        })
    }

    const clearSelection = () => {
        setIsSelectAllMode(false)
        setExcludedIds(new Set())
        setSelectedIds(new Set())
    }

    const handleDataStateChange = ({
        pagination: newPagination,
        sorting: newSorting,
        startDate: newStartDate,
        endDate: newEndDate,
        search: newSearch,
    }: {
        pagination: PaginationState
        sorting: SortingState
        startDate?: Date
        endDate?: Date
        search?: string
    }) => {
        setPagination(newPagination)
        setSorting(newSorting)

        // Update state values
        if (newStartDate !== undefined) setStartDate(newStartDate)
        if (newEndDate !== undefined) setEndDate(newEndDate)
        if (newSearch !== undefined) setSearchTerm(newSearch)

        // Load data with all parameters
        loadData({
            pagination: newPagination,
            sorting: newSorting,
            startDate: newStartDate !== undefined ? newStartDate : startDate,
            endDate: newEndDate !== undefined ? newEndDate : endDate,
            search: newSearch !== undefined ? newSearch : searchTerm,
        })

        // Clear selection when search/filter changes
        if (newSearch !== undefined || newStartDate !== undefined || newEndDate !== undefined) {
            clearSelection()
        }
    }

    const getAllItems = useCallback(async () => {
        if (!domain) return []

        try {
            const response = await listGarmentTaggingRequest(domain, {
                page: 1,
                page_size: 999999,
                sort_by: 'created_at',
                sort_desc: true,
                search: searchTerm,
            })

            return response.data.items
        } catch (error) {
            console.error('Error fetching all items:', error)
            return []
        }
    }, [domain, searchTerm])

    const handleDeleteSelected = async (args: string[]) => {
        let items: string[] = []
        let mode = 'include'

        if (isSelectAllMode) {
            items = Array.from(excludedIds)
            mode = 'exclude'
        } else {
            items = Array.from(selectedIds)
            mode = 'include'
        }

        const confirmed = await showConfirmDialog({
            title: 'Confirm Deletion',
            message: `Are you sure you want to delete selected items? This action cannot be undone.`,
        })

        if (confirmed) {
            await dispatch(delGarmentTagging({ domain: domain, uuids: items, mode: mode }))
            clearSelection()
            loadData({
                pagination,
                sorting,
                startDate,
                endDate,
                search: searchTerm,
            })
        }
    }

    const isItemSelected = (uuid: string) => {
        if (isSelectAllMode) {
            return !excludedIds.has(uuid)
        }
        return selectedIds.has(uuid)
    }

    // Add a method to get the count of selected items
    const getSelectedCount = useCallback(async () => {
        if (isSelectAllMode) {
            const allItems = await getAllItems()
            return allItems.length - excludedIds.size
        }
        return selectedIds.size
    }, [isSelectAllMode, excludedIds.size, selectedIds.size, getAllItems])

    const handleStartDateChange = (date: Date | undefined) => {
        handleDataStateChange({
            pagination: { ...pagination, pageIndex: 0 },
            sorting,
            startDate: date,
            endDate,
        })
    }

    const handleEndDateChange = (date: Date | undefined) => {
        handleDataStateChange({
            pagination: { ...pagination, pageIndex: 0 },
            sorting,
            startDate,
            endDate: date,
        })
    }

    const handleDateChange = (start_date?: Date, end_date?: Date) => {
        handleDataStateChange({
            pagination: { ...pagination, pageIndex: 0 },
            sorting,
            startDate: start_date,
            endDate: end_date,
        })
    }

    const handleRowClick = (garment: GarmentTaggingT) => {
        setSelectedGarment(garment)
    }

    const handlePaginationChange: OnChangeFn<PaginationState> = (updaterOrValue) => {
        const newPagination = typeof updaterOrValue === 'function' ? updaterOrValue(pagination) : updaterOrValue

        handleDataStateChange({
            pagination: newPagination,
            sorting,
            startDate,
            endDate,
        })
    }

    const handleSortingChange: OnChangeFn<SortingState> = (updaterOrValue) => {
        const newSorting = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue

        handleDataStateChange({
            pagination,
            sorting: newSorting,
            startDate,
            endDate,
        })
    }

    const handleExportToExcel = async () => {
        if (getAllItems) {
            const allItems = await getAllItems()
            console.log('Exporting items:', allItems)
        }
    }

    const handleSearch = (searchTerm: string) => {
        handleDataStateChange({
            pagination: { ...pagination, pageIndex: 0 },
            sorting,
            startDate,
            endDate,
            search: searchTerm,
        })
    }

    const handleDownloadSelected = async (email: string) => {
        let items: string[] = []
        let mode = 'include'

        if (isSelectAllMode) {
            items = Array.from(excludedIds)
            mode = 'exclude'
        } else {
            items = Array.from(selectedIds)
            mode = 'include'
        }

        await dispatch(
            downloadGarmentTagging({
                domain: domain,
                email: [email],
                uuids: items,
                mode: mode,
            })
        )

        // Show success message
        toast.success('Download link will be sent to your email shortly')
    }

    return (
        <div className="flex flex-col h-full overflow-hidden px-4 pb-4 max-h-full">
            <div className="flex-none mb-4">
                <TimeFilters startDate={startDate} OnStartDateChange={handleStartDateChange} endDate={endDate} OnEndDateChange={handleEndDateChange} OnStartEndDateChange={handleDateChange} />
            </div>
            <PageLoader open={isGarmentTaggingLoading}>
                <div className="flex-1 min-h-0">
                    <GarmentTable
                        data={garmentTaggingItems}
                        totalItems={totalItems}
                        totalCompletedItems={totalCompletedItems}
                        pageCount={totalPages}
                        pagination={pagination}
                        sorting={sorting}
                        onPaginationChange={handlePaginationChange}
                        onSortingChange={handleSortingChange}
                        onDeleteSelected={handleDeleteSelected}
                        onDownloadSelected={handleDownloadSelected}
                        onExportToExcel={handleExportToExcel}
                        onSearchChange={handleSearch}
                        searchTerm={searchTerm}
                        selectedIds={selectedIds}
                        onSelectRow={handleSelect}
                        onSelectPage={handleSelectPage}
                        isSelectAllMode={isSelectAllMode}
                        onSelectAll={handleSelectAll}
                        isItemSelected={isItemSelected}
                        getSelectedCount={getSelectedCount}
                        onRowClick={handleRowClick}
                    />
                </div>
                {selectedGarment && <GarmentDetailModal isOpen={!!selectedGarment} onClose={() => setSelectedGarment(null)} garment={selectedGarment} />}
            </PageLoader>
        </div>
    )
}
